<footer>
  <div class="footer_top">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-3">
          <a class="footer_logo" href="#">
            <img
              src="assets/images/chaad-ride-logo.png"
              class="img-fluid"
              alt=""
              style="width: 150px"
            />
          </a>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-2">
          <h4>OUR INFO</h4>
          <ul class="our_info">
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a
                (click)="onClick($event)"
                routerLink="/about-us"
                routerLinkActive="active"
                >About</a
              >
            </li>
            <li>
              <a
                routerLink="/hub-create"
                *ngIf="authService.isLoggedin()"
                routerLinkActive="active"
                (click)="onClick($event)"
              >
                Hub Register
              </a>
            </li>
            <li>
              <a
                (click)="onClick($event)"
                routerLink="/gallery"
                routerLinkActive="active"
                >Vehicle List</a
              >
            </li>
            <li>
              <a routerLink="/faq" routerLinkActive="active">FAQ</a>
            </li>
            <li>
              <a routerLink="/services" routerLinkActive="active">Services</a>
            </li>
            <li>
              <a
                routerLink="/contact-us"
                routerLinkActive="active"
                (click)="onClick($event)"
                >Contact us</a
              >
            </li>
            <li>
              <a
                routerLink="/sign-in"
                *ngIf="!authService.isLoggedin()"
                routerLinkActive="active"
                >Sign in</a
              >
            </li>
          </ul>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-2">
          <div class="useful_links">
            <h4>USEFUL LINKS</h4>
            <ul class="our_info">
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a routerLink="/terms-condition" routerLinkActive="active"
                  >Terms & Conditions</a
                >
              </li>
              <li>
                <a
                  routerLink="/sign-up"
                  *ngIf="!authService.isLoggedin()"
                  routerLinkActive="active"
                  >Registration</a
                >
              </li>
              <li>
                <a href="#">Press Release</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <img src="assets/images/bike1.png" class="img-fluid footer_bike" alt="" />
  </div>
  <div class="footer_bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-8 copywrite">
          <p>
            &copy; ChaadRide.com [ v : 0.1.8 ]
            <span
              >Developed by<a
                href="https://www.simecsystem.com/"
                target="_blank"
                >SIMEC System Limited</a
              ></span
            >
          </p>
        </div>
        <div class="d-none d-sm-none d-md-none d-lg-block col-lg-4">
          <div class="footer_social">
            <ul>
              <li>
                <a href="#"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
