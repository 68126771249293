import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sorting-limit',
  templateUrl: './sorting-limit.component.html',
  styleUrls: ['./sorting-limit.component.css']
})
export class SortingLimitComponent implements OnInit {

  constructor() { }
@Output() onChangeLimitOption = new EventEmitter();
  ngOnInit(): void {
  }
  onChangeLimit(val){
    this.onChangeLimitOption.emit(val);
  }
}
