import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-xlxdownload',
  templateUrl: './xlxdownload.component.html',
  styleUrls: ['./xlxdownload.component.css'],
})
export class XlxdownloadComponent implements OnInit {
  constructor() {}

  @Output() exportToExcelDownload = new EventEmitter();

  ngOnInit(): void {}
  exportToExcel() {
    this.exportToExcelDownload.emit();
  }
}
