<div class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h6>&copy; ChaadRide.com [ v : {{version}} ]</h6>
            </div>
            <div class="col text-right">
             <h6><span>Powered by <a href="{{footerTextLink}}"  target="_blank">{{footerText}}</a></span></h6>
            </div>
        </div>
    </div>
</div>