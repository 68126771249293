<header class="">
  <div class="top-bar">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="top-bar-left">
            <ul>
              <li *ngFor ="let emergency_phone of emergency_number_list">
                <a href="tel:{{emergency_phone.emergency}}"><span><i class="fas fa-phone-alt"></i></span>{{emergency_phone.emergency}}</a>
              </li>
              <li>
                <a href="mailto:info@chadride.com"><span><i class="far fa-envelope"></i></span>info@chadride.com</a>
              </li>
              <li>
                <a href="#"><span><i class="fas fa-shopping-cart"></i></span>Add to Cart</a>
              </li>
              <li>
                <a routerLink="/gallery" routerLinkActive="active"><i class="fas fa-search"></i></a>
              </li>
            </ul>
          </div>
          <div class="top-bar-right">
            <ul>
              <li>
                <a href="#"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-instagram"></i></a>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
    <div class="header_menu">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav class="navbar navbar-expand-lg">
                  <a class="navbar-brand logo" href="#">
                      <img src="assets/images/chaad-ride-logo.png" class="img-fluid" alt="" style="width:200px;">
                  </a>
                  <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#HeaderMenu">

                      <span class="sr-only">Toggle navigation</span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="HeaderMenu">
                    <ul class="navbar-nav ml-auto header_top_menu">
                      <li class="nav-item active">
                        <a class="nav-link" routerLink="/" routerLinkActive="active"> Home </a>
                      </li>
                        <li class="nav-item">
                          <a class="nav-link" routerLink="/offers" routerLinkActive="active">Offers</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" routerLink="/services" routerLinkActive="active">Services</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" routerLink="/gallery" routerLinkActive="active">Gallery</a>
                        </li>
                        <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                          aria-expanded="false" *ngIf="authService.getUserAdmin()">Admin Panel</a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item"  routerLink="/admin-hub-list" routerLinkActive="active">Hubs List</a>
                            <a class="dropdown-item"  routerLink="/admin-users-list" routerLinkActive="active">Users List</a>
                            <a class="dropdown-item"  routerLink="/admin-vehilce-list" routerLinkActive="active">Vehicles List</a>
                          </div>
                        </li>
                        <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                          aria-expanded="false" *ngIf="authService.getUserHubOwner()">Dashboard</a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" routerLink="/my-hub-list" routerLinkActive="active">My Hub List</a>
                            <a class="dropdown-item"  routerLink="/vehicle-entry" routerLinkActive="active">Add Vehicle</a>
                            <a class="dropdown-item"  routerLink="/hub-vehicle-list" routerLinkActive="active">Vehicle List</a>
                            <a class="dropdown-item"  routerLink="/hub-booking-list" routerLinkActive="active">Booking List</a>
                            <a class="dropdown-item" routerLink="/trip-status" routerLinkActive="active">Trip Status</a>
                            <a class="dropdown-item" routerLink="/hub-employee-management" routerLinkActive="active">Add Employee</a>
                            <a class="dropdown-item" routerLink="/hub-employeee-list" routerLinkActive="active">Employee List</a>
                          </div>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" routerLink="/faq" routerLinkActive="active">Faq</a>
                        </li>
                        <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                          aria-expanded="false">more</a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item"  routerLink="/about-us" routerLinkActive="active">About Us</a>
                            <a class="dropdown-item" href="#">WHY CHAAD RIDE?</a>
                            <a *ngIf="authService.isLoggedin()" class="dropdown-item" routerLink="/damage-reporting" routerLinkActive="active">DAMAGE REPORTING</a>
                            <a class="dropdown-item" href="#">One Stop Shop</a>
                            <a class="dropdown-item" href="/hub-list"> Hub List</a>
                            <a *ngIf="authService.isLoggedin()" class="dropdown-item" href="/my-trips"> My Trips</a>
                            <a  class="dropdown-item" routerLink="/contact-us" routerLinkActive="active"> Contact Us</a>
                            <a *ngIf="!authService.isLoggedin()" class="dropdown-item" routerLink="/sign-in" routerLinkActive="active">Sign in</a>
                            <a *ngIf="!authService.isLoggedin()" class="dropdown-item" routerLink="/sign-up" routerLinkActive="active">Sign up</a>
                          </div>
                        </li>

                        <li class="nav-item dropdown" *ngIf="authService.isLoggedin()">
                          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                          aria-expanded="false">profile</a>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" routerLink="/my-profile" routerLinkActive="active"> My Profile</a>
                            <a class="dropdown-item" routerLink="/change-password" routerLinkActive="active"> Change Password </a>
                            <!-- <a class="dropdown-item" routerLink="/hub-profile" routerLinkActive="active"> My Hub list</a> -->
                            <!-- <a class="dropdown-item" routerLink="/hub-profile" routerLinkActive="active"> Hub Profile</a> -->
                            <a class="dropdown-item" style="cursor: pointer;" (click)="logOut()"> Log out</a>
                          </div>
                        </li>
                    </ul>
                  </div>
              </nav>
            </div>
        </div>
      </div>
    </div>

</header>
