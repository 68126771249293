import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';

import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import { SiteHeaderComponent } from './layout/site-header/site-header.component';
import { SiteFooterComponent } from './layout/site-footer/site-footer.component';

// ************** HUb dashboard *************** */
import { DashboardHeaderComponent } from './layout/hubDashboard/dashboard-header/dashboard-header.component';
import { DashboardSidebarComponent } from './layout/hubDashboard/dashboard-sidebar/dashboard-sidebar.component';
import { DashboardLayoutComponent } from './layout/hubDashboard/dashboard-layout/dashboard-layout.component';
import { DashboardFooterComponent } from './layout/hubDashboard/dashboard-footer/dashboard-footer.component';

// ************** Admin dashboard *************** */
import { AdminDashboardHeaderComponent } from './layout/adminDashboard/admin-dashboard-header/admin-dashboard-header.component';
import { AdminDashboardSidebarComponent } from './layout/adminDashboard/admin-dashboard-sidebar/admin-dashboard-sidebar.component';
import { AdminDashboardLayoutComponent } from './layout/adminDashboard/admin-dashboard-layout/admin-dashboard-layout.component';
import { AdminDashboardFooterComponent } from './layout/adminDashboard/admin-dashboard-footer/admin-dashboard-footer.component';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { XlxdownloadComponent } from './components/xlxdownload/xlxdownload.component';
import { SearchComponent } from './components/report/search/search.component';
import { ClearComponent } from './components/report/clear/clear.component';
import { BackComponent } from './components/report/back/back.component';
import { SortingLimitComponent } from './components/report/sorting-limit/sorting-limit.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoadingSpinnerComponent,
    DashboardHeaderComponent,
    DashboardFooterComponent,
    DashboardLayoutComponent,
    DashboardSidebarComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    AdminDashboardHeaderComponent,
    AdminDashboardSidebarComponent,
    AdminDashboardLayoutComponent,
    AdminDashboardFooterComponent,
    XlxdownloadComponent,
    SearchComponent,
    ClearComponent,
    BackComponent,
    SortingLimitComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    // BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoadingSpinnerComponent,
    XlxdownloadComponent,
    BackComponent,
    ClearComponent,
    SearchComponent,
    SortingLimitComponent
  ],
})
export class SharedModule {}
