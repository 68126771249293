import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.css']
})
export class BackComponent implements OnInit {

  constructor() { }
  @Output() backToUrl = new EventEmitter();
  ngOnInit(): void {
  }
  backToReport(){
    this.backToUrl.emit();
  }
}
