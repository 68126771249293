import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo, retry, catchError } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  public base_url = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging
  ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
      const data = {};
      data[userId] = token;
      this.angularFireDB.object('fcmTokens/').update(data);
    });
  }

  updateDatabaseToken(token) {
    // we can change this function to request our backend service
    // /api/v1/auth/fcm-token
    // console.log('auth/fcm-token`, token', token);
    // if(localStorage.getItem('isLoggedin') && localStorage.getItem('isLoggedin') === 'yes')
    {
      return this.http.put(`${this.base_url}auth/fcm-token`, token).pipe(
        retry(0),
        catchError((err) => {
          return throwError(err);
        })
      );
    }
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // console.log("firebase token", token);
        // this.updateToken(userId, token);
        if (token) {
          if (
            localStorage.getItem('isLoggedin') &&
            localStorage.getItem('isLoggedin') === 'yes'
          ) {
            this.updateDatabaseToken({
              fcmToken: token,
              requestDevice: 'WEB',
            }).subscribe((data) => {
              // console.log(data);
            });
          }
        }
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log('new message received. ', payload);
      this.currentMessage.next(payload);
    });
  }
}
