import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-dashboard-footer',
  templateUrl: './admin-dashboard-footer.component.html',
  styleUrls: ['./admin-dashboard-footer.component.css']
})
export class AdminDashboardFooterComponent implements OnInit {
    public version = environment.version;
    public footerText = environment.footerText;
    public footerTextLink = environment.footerTextLink;
  constructor() { }

  ngOnInit(): void {
  }

}
