import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  constructor() { }
@Output() searchReport = new EventEmitter();
  ngOnInit(): void {
  }
  searchFilterReport(){
    this.searchReport.emit();
  }
}
