import { NgModule } from '@angular/core';
import { ChdCurrencyPipe } from './shared/common/pipes/chd.currency.pipe';

@NgModule({
  imports: [],
  declarations: [ChdCurrencyPipe],
  exports: [ChdCurrencyPipe],
})
export class PipesModule {
  //   static forRoot() {
  //     return { ngModule: PipeModule, providers: [] };
  //   }
}
