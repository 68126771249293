import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError, take } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { promise } from 'selenium-webdriver';
import { IUserRolePrivilege } from 'src/app/admin/user/interfaces/user-role.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // public base_url = 'http://k.aws.simecsystem.com/api/v1';
  public accessToken: string = null;

  public base_url = environment.baseUrl;

  constructor(private http: HttpClient) {}

  //***************  Emergency Number  *****************
  getEmergencyNumber() {
    return this.http.get(`${this.base_url}other/env/emergency`);
  }

  login(signIn) {
    // return this.http.post(`${this.base_url}auth/signin`, signIn).pipe(
    //   retry(0),
    //   catchError(this.handleError)
    // );
    return this.http.post(`${this.base_url}auth/signin`, signIn).pipe(
      retry(0),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getUserRole() {
    return this.http.get(`${this.base_url}auth/user-role`).pipe(
      retry(0),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  userInfo() {
    return this.http.get(`${this.base_url}user/me`).pipe(
      retry(0),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  bookingTripExtension() {
    return this.http.get(`${this.base_url}trip/running`).pipe(
      retry(0),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getAllNotification(start: number, limit: number) {
    return this.http
      .get(`${this.base_url}remarks?limit=${limit}&start=${start}`)
      .pipe(
        retry(0),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getNotificationCount() {
    return this.http.get(`${this.base_url}remarks/remark-count/unread`).pipe(
      retry(0),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // getUserRole() {
  //   return this.http.get(`${this.base_url}hub-booking`).pipe(
  //     retry(0),
  //     catchError(err => {
  //       return throwError(err);
  //     })
  //   );
  // }

  // private handleError(error :HttpErrorResponse) {
  //   console.log(error);
  //   return throwError(error.error);
  // }

  isLoggedin() {
    return localStorage.getItem('isLoggedin') === 'yes';
  }

  // getUserCatgory() {
  //   // console.log(localStorage);
  //   // console.log(localStorage.length);
  //   // console.log(localStorage.userProfile);

  //   // if(localStorage.userRole !== undefined){
  //   //   let userRole = JSON.parse(localStorage['userRole']);
  //   //   console.log(userRole['VISITOR']);
  //   // }

  //   if(localStorage.length !== 0 || localStorage.userProfile !== undefined){
  //     let userInfo = JSON.parse(localStorage['userProfile']);
  //   // this.userCatagory = userInfo["catagory"];
  //     return userInfo["catagory"];
  //   }else{
  //     return "not_found";
  //   }
  // }

  getUserHubOwner() {
    if (localStorage.userRole !== undefined) {
      let userRole = JSON.parse(localStorage['userRole']);
      if (userRole['HUB_OWNER'] === true) {
        return true;
      } else {
        return false;
      }
    }
  }

  getUserHubEmploy() {
    if (localStorage.userRole !== undefined) {
      let userRole = JSON.parse(localStorage['userRole']);
      if (
        userRole['HUB_OWNER'] === true ||
        userRole['HUB_ENGINEER'] === true ||
        userRole['HUB_DELIVERY'] === true ||
        userRole['HUB_SALESMAN'] === true ||
        userRole['HUB_ACCOUNTANT'] === true
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  getUserRegister() {
    if (localStorage.userRole !== undefined) {
      let userRole = JSON.parse(localStorage['userRole']);
      if (userRole['REGISTER'] === true) {
        return true;
      } else {
        return false;
      }
    }
  }

  getUserAdmin() {
    if (localStorage.userRole !== undefined) {
      let userRole = JSON.parse(localStorage['userRole']);
      if (userRole['ADMIN'] === true || userRole['SUPERADMIN'] === true) {
        return true;
      } else {
        return false;
      }
    }
  }
  setAdimUserRoleData(adminMenuList: string) {
    localStorage.setItem('adminUerRole', adminMenuList);
  }

  getAdminRoleuserList() {
    return this.http.get(`${this.base_url}admin/user/role-group/list`).pipe(
      retry(0),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  adminRoleMenuList() {
    return this.http.get(`${this.base_url}auth/user-role/menu`).pipe(
      retry(0),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getAccessToken(): any {
    if (!this.accessToken) {
      this.accessToken = localStorage.getItem('accessToken');
    }

    return this.accessToken;
  }

  isAuthenticated(): Boolean {
    return this.getAccessToken() ? true : false;
  }

  isAdminAuthenticated(): Boolean {
    return this.getUserAdmin() ? true : false;
  }

  removeToken() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('userProfile');
    localStorage.removeItem('userRole');
    localStorage.clear();
  }

  getUserPrivileges() {
    const superAdmin = JSON.parse(localStorage.getItem('userRole'));
    const menuRole: { [key: string]: IUserRolePrivilege } = JSON.parse(
      localStorage.getItem('menuRole')
    );
    return menuRole;
  }
}
