import { Component, OnInit } from '@angular/core';
import { IUserRolePrivilege } from 'src/app/admin/user/interfaces/user-role.interface';
import { UserService } from 'src/app/admin/user/service/user.service';
import { AuthService } from 'src/app/shared/services/auth-service.service';

@Component({
  selector: 'app-admin-dashboard-sidebar',
  templateUrl: './admin-dashboard-sidebar.component.html',
  styleUrls: ['./admin-dashboard-sidebar.component.css']
})
export class AdminDashboardSidebarComponent implements OnInit {

  public adminRoleUsers: { count: 0, menu: string[] } = { count: 0, menu: [] };
  public OwnRole : IUserRolePrivilege[] = [];
  public menuRole : {[key: string]: IUserRolePrivilege} = {};
  public superAdmin: any = {};

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.superAdmin = JSON.parse(localStorage.getItem('userRole'));
    // console.log('superAdmin',this.superAdmin)


      
      this.authService.getAdminRoleuserList().subscribe((data) => {
        // console.log(data);
        this.adminRoleUsers = {
          "count": data['count'],
          "menu": data['payload'].map(m => Object.keys(m)[0])
        };
        this.adminRoleUsers.menu.forEach(menu => {
          this.menuRole[menu] = {
            menu: menu,
            activity : {
              READ : false,
              WRITE : false,
              UPDATE : false,
              DELETE : false,
              PRINT : false
            },
            isActive : true
          };
        });
        this.authService.adminRoleMenuList().subscribe(ownRoleData => {
          // console.log(ownRoleData["payload"]);
          this.OwnRole = ownRoleData["payload"].map(role => {
            this.menuRole[role['menu']].activity = role['activity'];
            return {
              menu: role['menu'],
              activity: role['activity'],
              isActive : true
            };
          });
          // console.log(this.OwnRole);
          // console.log(this.menuRole);
          localStorage.setItem('menuRole', JSON.stringify(this.menuRole));
        });
      });
  }

}
