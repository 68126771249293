<ul
  class="
    navbar-nav
    bg-gradient-primary
    sidebar sidebar-dark
    accordion
    sidebarMenu
  "
  id="accordionSidebar"
>
  <li class="nav-item">
    <a class="nav-link" routerLink="/hub-admin" routerLinkActive="active">
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      Dashboard
    </a>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#hubMangement"
      aria-expanded="false"
      aria-controls="hubMangement"
    >
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Hubs</span>
    </a>
    <div
      id="hubMangement"
      class="collapse headingBorder"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          class="collapse-item"
          routerLink="/hub-admin/hubs/list"
          routerLinkActive="active"
          >Hub List</a
        >
        <a
          class="collapse-item"
          routerLink="/hub-admin/hubs/create"
          routerLinkActive="active"
          >Hub Create</a
        >
      </div>
    </div>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#veicleManagement"
      aria-expanded="false"
      aria-controls="veicleManagement"
    >
      <img
        src="../../../../assets/img/vehicle_management_icon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Vehicle</span>
    </a>
    <div
      id="veicleManagement"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          class="collapse-item"
          routerLink="/hub-admin/vehicles/list"
          routerLinkActive="active"
          >Vehicles list</a
        >
        <a
          class="collapse-item"
          routerLink="/hub-admin/vehicles/create"
          routerLinkActive="active"
          >Add new vehicle</a
        >
      </div>
    </div>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#veicleServicng"
      aria-expanded="false"
      aria-controls="veicleServicng"
    >
      <img
        src="../../../../assets/img/vehicle_management_icon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Vehicles Servicing</span>
    </a>
    <div
      id="veicleServicng"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          class="collapse-item"
          routerLink="/hub-admin/vehicle-service/list"
          routerLinkActive="active"
          >list</a
        >
      </div>
    </div>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#bookingManagement"
      aria-expanded="false"
      aria-controls="bookingManagement"
    >
      <img
        src="../../../../assets/img/Booking_manage.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Bookings</span>
    </a>
    <div
      id="bookingManagement"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          class="collapse-item"
          routerLink="/hub-admin/booking/list"
          routerLinkActive="active"
          >Booking List</a
        >
        <a
          class="collapse-item"
          routerLink="/hub-admin/booking/created"
          routerLinkActive="active"
          >Booking Created</a
        >
        <a
          class="collapse-item"
          routerLink="/hub-admin/booking/waiting"
          routerLinkActive="active"
          >Booking Pending</a
        >
        <a
          class="collapse-item"
          routerLink="/hub-admin/booking/start"
          routerLinkActive="active"
          >Booking On Trip</a
        >
        <!-- <a class="collapse-item" routerLink="/hub-admin/booking/extension" routerLinkActive="active">Booking Extension</a> -->
        <a
          class="collapse-item"
          routerLink="/hub-admin/booking/endtrip"
          routerLinkActive="active"
          >Booking End Trip</a
        >
        <!-- <a class="collapse-item" routerLink="/hub-admin/booking/cancel" routerLinkActive="active">Booking Cancel</a> -->
        <a
          class="collapse-item"
          routerLink="/hub-admin/booking/penalty"
          routerLinkActive="active"
        >Booking Penalty List</a
        >
      </div>
    </div>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/hub-admin/damage/list">
      <img
        src="../../../../assets/img/damage_Manage.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Damage</span>
    </a>
    <!-- <div id="damageManagement" class="collapse headingBorder" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
            <div class="py-2 collapse-inner subMenuList">
                <a class="collapse-item" routerLink="/hub-admin/damage/list" routerLinkActive="active">Damage List</a>
            </div>
        </div> -->
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#EmployeeManagement"
      aria-expanded="false"
      aria-controls="EmployeeManagement"
    >
      <img
        src="../../../../assets/img/employee_management.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Employees</span>
    </a>
    <div
      id="EmployeeManagement"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          class="collapse-item"
          routerLink="/hub-admin/employee/list"
          routerLinkActive="active"
          >My Employees list</a
        >
        <a
          class="collapse-item"
          routerLink="/hub-admin/employee/management"
          routerLinkActive="active"
          >Add new Employee</a
        >
      </div>
    </div>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#driverTraining"
      aria-expanded="false"
      aria-controls="driverTraining"
    >
      <img
        src="../../../../assets/img/vehicle_management_icon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Driving Training</span>
    </a>
    <div
      id="driverTraining"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          class="collapse-item"
          routerLink="/hub-admin/driving-training/list"
          routerLinkActive="active"
          >list</a
        >
      </div>
    </div>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#accountManagement"
      aria-expanded="false"
      aria-controls="accountManagement"
    >
      <img
        src="../../../../assets/img/accounts_manage.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Accounts</span>
    </a>
    <div
      id="accountManagement"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          class="collapse-item"
          routerLink="/hub-admin/billing/list"
          routerLinkActive="active"
          >Billing List</a
        >
      </div>
    </div>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#hubInventory"
      aria-expanded="false"
      aria-controls="hubInventory"
    >
      <img
        src="../../../../assets/img/accounts_manage.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Inventory</span>
    </a>
    <div
      id="hubInventory"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          class="collapse-item"
          routerLink="/hub-admin/hub-inventory/vehicle-list"
          routerLinkActive="active"
          >Vehicle List</a
        >
      </div>
    </div>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/hub-admin/reports">
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span> Report</span>
    </a>
  </li>
</ul>
