import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.css']
})
export class SiteFooterComponent implements OnInit {
  public version = environment.version;
  public footerText = environment.footerText;
  public footerTextLink = environment.footerTextLink;
  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }
  onClick(event) {
    window.scroll(0,0);
  }

}
