// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: '0.2.96-037d12b',
  production: false,
  // baseUrl1: 'http://k.aws.simecsystem.com/api/v1/',
  //   baseUrl: 'http://192.168.10.198:12001/api/v1/',
  // baseUrl: 'https://chaadride.py.aws.simecsystem.com/api/v1/',
  baseUrl: 'https://chaadride.com/api/v1/',
  // baseUrl: 'http://10.11.105.127:12001/api/v1/',
  //baseUrl: 'http://10.11.105.102/api/v1/',
  // baseUrl: 'http://localhost:8000/api/v1/',
  // baseUrl: 'http://localhost:12001/api/v1/',
  //baseUrl: 'http://localhost/api/v1/',
  //  baseUrl: 'https://chaadride.py.aws.simecsystem.com/api/v1/',
  // baseUrl: 'https://chaadride.com/api/v1/',
  // baseUrl: 'http://192.168.10.112:12001/api/v1/',
  footerText: 'Spotter Private Limited',
  footerTextLink: 'http://spotterpvtltd.com/',
  sslcommerz_url: 'https://seamless-epay.sslcommerz.com/embed.min.js?',
  // firebase Web Setup
  firebase: {
    apiKey: 'AIzaSyCsOFCmmxkKLUV8lYmZv8-rpCjHtireOkU',
    authDomain: 'chaadride-6ae0d.firebaseapp.com',
    projectId: 'chaadride-6ae0d',
    storageBucket: 'chaadride-6ae0d.appspot.com',
    messagingSenderId: '686904629228',
    appId: '1:686904629228:web:9af46234d2ef1b09e112c9',
    measurementId: 'G-XP5267P93R',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
