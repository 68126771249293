<header class="">
  <div class="top-bar">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="top-bar-left">
            <ul>
              <li *ngFor="let emergency_phone of emergency_number_list">
                <a href="tel:{{ emergency_phone.emergency }}"
                  ><span><i class="fas fa-phone-alt"></i></span
                  >{{ emergency_phone.emergency }}</a
                >
              </li>
              <li>
                <a href="mailto:info@chadride.com"
                  ><span><i class="far fa-envelope"></i></span
                  >info@chaadride.com</a
                >
              </li>
              <!-- <li>
                  <a href="#"><span><i class="fas fa-shopping-cart"></i></span>Add to Cart</a>
                </li> -->
             <!-- <li class="bike_gallery_search_bar">
                <a
                  routerLink="/gallery"
                  routerLinkActive="active"
                  class="text-white text-decoration-none"
                >
                  Vehicle Gallery
                  <i class="fas fa-search text-white pl-1"></i>
                </a>
              </li>-->
            </ul>
          </div>
          <div class="top-bar-right">
            <ul class="notification">
              <li *ngIf="authService.isLoggedin()" id="notifications" dropdown>
                <a
                  id="button-basic"
                  dropdownToggle
                  type="button"
                  class=""
                  aria-controls="dropdown-basic"
                >
                  <i class="fas fa-bell"></i
                  ><span>{{ notificationUnreadCount }}</span>
                </a>
                <div class="notificationDiv">
                  <ul
                    *dropdownMenu
                    id="dropdown-basic"
                    class="dropdown-menu"
                    role="menu"
                    aria-labelledby="button-basic"
                  >
                    <li *ngFor="let notification of notificationList.data">
                      <span class="nTitle">{{ notification.title }}</span>
                      <span class="nMsg">{{ notification.msg }}</span>
                      <span class="nDate">{{
                        notification.dt | date: "medium"
                      }}</span>
                    </li>
                    <div class="text-center py-3">
                      <button
                        type="button"
                        class="btn btn-orange notification-btn"
                        (click)="notification()"
                      >
                        See More
                      </button>
                    </div>
                  </ul>
                </div>
              </li>
              <li>
                <a target="_blank" href="https://www.facebook.com/chaadride"
                  ><i class="fab fa-facebook-f"></i
                ></a>
              </li>
              <li>
                <a target="_blank" href="https://www.instagram.com/chaad.ride/"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
              <li>
                <a target="_blank" href="https://www.linkedin.com/company/chaad-ride/"
                ><i class="fab fa-linkedin"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="header_menu">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand logo" href="#">
              <img
                src="assets/images/chaad-ride-logo.png"
                class="img-fluid"
                alt=""
                style="width: 200px"
              />
            </a>
            <button
              type="button"
              class="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#HeaderMenu"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse" id="HeaderMenu">
              <ul class="navbar-nav ml-auto header_top_menu">
                <li class="nav-item active">
                  <a class="nav-link" routerLink="/" routerLinkActive="active">
                    Home
                  </a>
                </li>
                <li class="nav-item active">
                  <a
                    class="nav-link"
                    routerLink="/about-us"
                    routerLinkActive="active"
                  >
                    About Us
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >Services</a
                  >
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      routerLink="/bike-services"
                      routerLinkActive="active"
                      >Bike Service</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/driving-training"
                      routerLinkActive="active"
                      >Driving Training</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/insurance"
                      routerLinkActive="active"
                      >Insurance Help</a
                    >
                  </div>
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/offers"
                    routerLinkActive="active"
                    >Offers</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/contact-us"
                    routerLinkActive="active"
                  >
                    Contact Us</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    *ngIf="!authService.isLoggedin()"
                    class="nav-link"
                    routerLink="/auth/sign-in"
                    routerLinkActive="active"
                    >Sign in</a
                  >
                </li>
                <li
                  class="nav-item"
                  *ngIf="authService.isLoggedin() && this.tripCount != 0"
                >
                  <a
                    class="nav-link blinking"
                    routerLink="/tripon"
                    routerLinkActive="active"
                    >Trip On
                  </a>
                </li>

                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >more</a
                  >
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      routerLink="/faq"
                      routerLinkActive="active"
                      >Faq</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/hubs"
                      routerLinkActive="active"
                    >
                      Hub List</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/gallery"
                      routerLinkActive="active"
                      >Vehicle Gallery</a
                    >
                    <a class="dropdown-item" href="#chaad_ride"
                      >WHY CHAAD RIDE?</a
                    >
                    <a class="dropdown-item" routerLink="/terms-condition">
                      Terms & Conditions</a
                    >
                    <a
                      *ngIf="authService.isLoggedin()"
                      class="dropdown-item"
                      routerLink="/damage-reporting"
                      routerLinkActive="active"
                      >DAMAGE REPORTING</a
                    >
                    <a
                      *ngIf="authService.isLoggedin()"
                      class="dropdown-item"
                      routerLink="/my-trips"
                    >
                      My Trips</a
                    >
                    <a
                      *ngIf="!authService.isLoggedin()"
                      class="dropdown-item"
                      routerLink="/auth/sign-up"
                      routerLinkActive="active"
                      >Sign up</a
                    >
                  </div>
                </li>
                &lt;!&ndash; <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                            aria-expanded="false" *ngIf="authService.getUserAdmin()">Admin Panel</a>
                            <div class="dropdown-menu">
                              <a class="dropdown-item"  routerLink="/admin-hub-list" routerLinkActive="active">Hubs List</a>
                              <a class="dropdown-item"  routerLink="/admin-users-list" routerLinkActive="active">Users List</a>
                              <a class="dropdown-item"  routerLink="/admin-vehilce-list" routerLinkActive="active">Vehicles List</a>
                            </div>
                          </li> &ndash;&gt;
                &lt;!&ndash; <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                            aria-expanded="false" *ngIf="authService.getUserHubOwner()">Dashboard</a>
                            <div class="dropdown-menu"> &ndash;&gt;
                &lt;!&ndash; <a class="dropdown-item" routerLink="/my-hub-list" routerLinkActive="active">My Hub List</a> &ndash;&gt;
                &lt;!&ndash; <a class="dropdown-item"  routerLink="/vehicle-entry" routerLinkActive="active">Add Vehicle</a> &ndash;&gt;
                &lt;!&ndash; <a class="dropdown-item"  routerLink="/hub-vehicle-list" routerLinkActive="active">Vehicle List</a> &ndash;&gt;
                &lt;!&ndash; <a class="dropdown-item"  routerLink="/hub-booking-list" routerLinkActive="active">Booking List</a>
                              <a class="dropdown-item" routerLink="/trip-status" routerLinkActive="active">Trip Status</a>
                              <a class="dropdown-item" routerLink="/hub-employee-management" routerLinkActive="active">Add Employee</a>
                              <a class="dropdown-item" routerLink="/hub-employeee-list" routerLinkActive="active">Employee List</a>
                            </div>
                          </li> &ndash;&gt;

                <li class="nav-item dropdown" *ngIf="authService.isLoggedin()">
                  <a
                    class="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >profile</a
                  >
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      routerLink="/admin"
                      routerLinkActive="active"
                      *ngIf="authService.getUserAdmin()"
                    >
                      Admin dashboard
                    </a>
                    <a
                      class="dropdown-item"
                      routerLink="/hub-admin"
                      routerLinkActive="active"
                      *ngIf="authService.getUserHubOwner()"
                    >
                      Hub dashboard
                    </a>
                    <a
                      class="dropdown-item"
                      routerLink="/my-profile"
                      routerLinkActive="active"
                    >
                      My Profile</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/notifications"
                      routerLinkActive="active"
                    >
                      Notification</a
                    >

                    <a
                      class="dropdown-item"
                      routerLink="/auth/change-password"
                      routerLinkActive="active"
                    >
                      Change Password
                    </a>
                    &lt;!&ndash; <a class="dropdown-item" routerLink="/hub-profile" routerLinkActive="active"> My Hub list</a> &ndash;&gt;
                    &lt;!&ndash; <a class="dropdown-item" routerLink="/hub-profile" routerLinkActive="active"> Hub Profile</a> &ndash;&gt;
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      (click)="logOut()"
                    >
                      Log out</a
                    >
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>-->

  <div class="header_menu">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand logo" href="#">
              <img
                src="assets/images/chaad-ride-logo.png"
                class="img-fluid"
                alt=""
                style="width: 200px"
              />
            </a>
            <button
              type="button"
              class="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#HeaderMenu"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse" id="HeaderMenu">
              <ul class="navbar-nav ml-auto header_top_menu">
                <li class="nav-item active">
                  <a class="nav-link" routerLink="/" routerLinkActive="active">
                    Home
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >Services</a
                  >
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      routerLink="/bike-services"
                      routerLinkActive="active"
                    >Bike Service</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/driving-training"
                      routerLinkActive="active"
                    >Driving Training</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/insurance"
                      routerLinkActive="active"
                    >Insurance Help</a
                    >
                  </div>
                </li>
                <li class="nav-item">
                    <a
                      *ngIf="!authService.isLoggedin()"
                      class="nav-link"
                      routerLink="/auth/sign-in"
                      routerLinkActive="active"
                    >Sign in</a>
                </li>
                <li class="nav-item">
                  <a
                    *ngIf="!authService.isLoggedin()"
                    class="nav-link"
                    routerLink="/auth/sign-up"
                    routerLinkActive="active"
                  >Sign up</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/gallery"
                    routerLinkActive="active"
                  >Vehicle Gallery</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/offers"
                    routerLinkActive="active"
                  >Offers</a
                  >
                </li>
                <li
                  class="nav-item"
                  *ngIf="authService.isLoggedin() && this.tripCount != 0"
                >
                  <a
                    class="nav-link blinking"
                    routerLink="/tripon"
                    routerLinkActive="active"
                  >Trip On
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/hubs"
                    routerLinkActive="active"
                  >
                    Hub List</a
                  >
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >more</a
                  >
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      routerLink="/faq"
                      routerLinkActive="active"
                    >Faq</a
                    >
                    <a class="dropdown-item" href="#chaad_ride"
                    >WHY CHAAD RIDE?</a
                    >
                    <a class="dropdown-item" routerLink="/terms-condition">
                      Terms & Conditions</a
                    >
                    <a
                      *ngIf="authService.isLoggedin()"
                      class="dropdown-item"
                      routerLink="/damage-reporting"
                      routerLinkActive="active"
                    >DAMAGE REPORTING</a
                    >
                    <a
                      *ngIf="authService.isLoggedin()"
                      class="dropdown-item"
                      routerLink="/my-trips"
                    >
                      My Trips</a
                    >
                  <a
                    class="dropdown-item"
                    routerLink="/about-us"
                    routerLinkActive="active"
                  >
                    About Us
                  </a>
                    <a
                      class="dropdown-item"
                      routerLink="/contact-us"
                      routerLinkActive="active"
                    >
                      Contact Us</a
                    >
                  </div>
                </li>
                <li class="nav-item dropdown" *ngIf="authService.isLoggedin()">
                  <a
                    class="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >profile</a
                  >
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      routerLink="/admin"
                      routerLinkActive="active"
                      *ngIf="authService.getUserAdmin()"
                    >
                      Admin dashboard
                    </a>
                    <a
                      class="dropdown-item"
                      routerLink="/hub-admin"
                      routerLinkActive="active"
                      *ngIf="authService.getUserHubOwner()"
                    >
                      Hub dashboard
                    </a>
                    <a
                      class="dropdown-item"
                      routerLink="/my-profile"
                      routerLinkActive="active"
                    >
                      My Profile</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/notifications"
                      routerLinkActive="active"
                    >
                      Notification</a
                    >

                    <a
                      class="dropdown-item"
                      routerLink="/auth/change-password"
                      routerLinkActive="active"
                    >
                      Change Password
                    </a>
                    <!-- <a class="dropdown-item" routerLink="/hub-profile" routerLinkActive="active"> My Hub list</a> -->
                    <!-- <a class="dropdown-item" routerLink="/hub-profile" routerLinkActive="active"> Hub Profile</a> -->
                    <a
                      class="dropdown-item"
                      style="cursor: pointer"
                      (click)="logOut()"
                    >
                      Log out</a
                    >
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>

</header>
