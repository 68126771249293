<div class="divalignleft">
  <div class="col-auto divalignleftSelect">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">Entries</div>
      </div>
      <select
        (change)="onChangeLimit($event.target.value)"
        class="custom-select custom-select-sm form-control form-control-sm"
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
        <option value="300">300</option>
      </select>
      <div class="input-group-prepend">
        <div class="input-group-text">Show</div>
      </div>
    </div>
  </div>
  <label> </label>
</div>
