import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth-service.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit {
    private userProfile: any;
    public userInfo:any = {};
    constructor(
        public authService: AuthService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.authService.userInfo().subscribe((data)=>{
            this.userInfo =  data["payload"][0];
        });
        this.userProfile = JSON.parse(localStorage.getItem('userProfile'));
    }

    logOut(){
        Swal.fire({
        title: 'Are you sure want to Log Out?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f26522',
        cancelButtonColor: '#3d1c0c',
        confirmButtonText: 'Yes, Log out!'
        }).then((result) => {
        if (result.value) {
            this.authService.removeToken();
            this.router.navigateByUrl('');
            Swal.fire(
            'Log Out Successful!',
            'Your\'re successfully logout',
            'success'
            )
        }
        })
    }

}
