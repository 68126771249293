import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css'],
})
export class SiteHeaderComponent implements OnInit {
  public isLoggedIn: boolean = false;
  public userCatagory: any;
  emergency_number_list: any = [];
  public tripCount: number = 0;
  public config: any;
  public isLoading = false;
  public notificationList = { count: 0, data: [] };
  public sl: number = 0;
  public notificationUnreadCount = 0;

  constructor(public authService: AuthService, private router: Router) {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      start: 0,
      totalItems: 0,
    };
  }

  ngOnInit(): void {
    this.authService.getEmergencyNumber().subscribe((data) => {
      this.emergency_number_list = data['payload'];
    });
    if (
      localStorage.getItem('isLoggedin') &&
      localStorage.getItem('isLoggedin') === 'yes'
    ) {
      this.authService.bookingTripExtension().subscribe((data) => {
        this.tripCount = data['count'];
        //  console.log('tripCount', this.tripCount);
      });
      this.getAllNotification();
    }

    // console.log("hellooooooooooooooooo");

    // this.isLoggedIn = this.authService.isLoggedin();
    // let userInfo = JSON.parse(localStorage.userProfile);
    // this.userCatagory = userInfo["catagory"];
    // console.log(userInfo, userInfo["catagory"]);
    // console.log(this.userCatagory);

    // console.log("Check response", this.userCatagory);
    // console.log(JSON.parse(localStorage.userProfile));
    // console.log(localStorage);
    // this.getAllNotification();
  }

  getAllNotification() {
    this.isLoading = true;
    const start = this.config.start;
    const limit = this.config.itemsPerPage;
    this.authService.getAllNotification(start, limit).subscribe((data) => {
      this.sl = (this.config.currentPage - 1) * this.config.itemsPerPage;
      this.notificationList = { count: data['count'], data: data['payload'] };
      this.config.totalItems = data['count'];
      this.isLoading = false;
      // console.log('notificationList', this.notificationList);
    });
    this.authService.getNotificationCount().subscribe((data) => {
      console.log(data['payload'][0]['remarkUnreadCount']);
      this.notificationUnreadCount =
        data['payload'][0]['remarkUnreadCount'] ?? 0;
    });
  }
  notification() {
    this.router.navigate(['/notifications']);
  }
  logOut() {
    // Swal.fire(
    //   'Thank you! Logout success',
    //   'You clicked the button!',
    //   'success'
    // );

    Swal.fire({
      title: 'Are you sure to Log Out?',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f26522',
      cancelButtonColor: '#3d1c0c',
      confirmButtonText: 'Yes, Log out!',
    }).then((result) => {
      if (result.value) {
        this.authService.removeToken();
        this.router.navigateByUrl('');
        Swal.fire(
          'Log Out Successful!',
          "Your're successfully logout",
          'success'
        );
      }
    });
  }
}
