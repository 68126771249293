<div class="divalignright">
  <div class="listGroup">
    <ul>
      <li>
        <a class="xlxDownload"
          ><img
            src="../../../../../assets/images/excel.png"
            (click)="exportToExcel()"
        /></a>
      </li>
    </ul>
  </div>
</div>
