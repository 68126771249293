import { AdminDashboardLayoutComponent } from './shared/layout/adminDashboard/admin-dashboard-layout/admin-dashboard-layout.component';
import { DashboardLayoutComponent } from './shared/layout/hubDashboard/dashboard-layout/dashboard-layout.component';
import { SiteLayoutComponent } from './shared/layout/site-layout/site-layout.component'
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [ 
    {
        path: '',
        component: SiteLayoutComponent,
        children: [
          { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
          { path: '', loadChildren: () => import('./modules/all-modules.module').then(m => m.AllModulesModule) },
        ]
    },
    {
        path: 'auth',
        component: SiteLayoutComponent,
        children: [
          { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
        ]
    },
    {
        path: 'hub-admin',
        component: DashboardLayoutComponent,
        children: [
          { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
        ]
    },
    {
        path: 'admin',
        component: AdminDashboardLayoutComponent,
        children: [
          { path: '', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
        ]
    },
   
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})

export class AppRoutingModule { }


