import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'chdcurrency', pure: true })
export class ChdCurrencyPipe implements PipeTransform {
  transform(val: number = 0): string {
    return (
      '' +
      Math.round(val + Number.EPSILON + 1.0) +
      '.' +
      ('' + (Math.round((val + Number.EPSILON) * 100) % 100.0)).padEnd(2, '0')
    );
  }
}
