import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from '../../services/auth-service.service';
import { Inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

const TOKEN_HEADER_KEY = 'Authorization';
const RECAPTCHA_HEADER_KEY = 'reCAPTCHA';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(AuthService) private authService: AuthService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let authReq = req;
    // const token = this.authService.getAccessToken();
    const token = localStorage.getItem('accessToken');
    //const reCAPTCHA = localStorage.getItem("reCAPTCHA");
    // console.log(token);
    if (token != null) {
      authReq = req.clone({
        headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token),
      });
    }
    // if(reCAPTCHA != null) {
    //     authReq = req.clone({
    //         headers: req.headers.set(RECAPTCHA_HEADER_KEY, btoa(reCAPTCHA))
    //     });
    // }
    // console.log(authReq);
    return next.handle(authReq).pipe(
      catchError((err) => {
        console.log({ err });
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            Swal.fire({
              title: 'Session Out',
              text: 'You Are already Sign Out !, Please, LogIn again',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#f26522',
              cancelButtonColor: '#3d1c0c',
              confirmButtonText: 'Ok',
            }).then((result) => {
              this.authService.removeToken();
              this.router.navigateByUrl(`/auth/sign-in`);
            });
          }
          return throwError(err);
        }
      })
    );
  }
}
