
<app-dashboard-header></app-dashboard-header>
<div id="wrapper">
    <aside class="dashboardLeftSidebar">
        <app-dashboard-sidebar>/</app-dashboard-sidebar>     
    </aside>
    <div class="dashboardPageWrapper">        
        <div class="mainDashboardSec py-3 pl-3 pr-3">
            <div class="containe-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="containe-fluid">
            <app-dashboard-footer></app-dashboard-footer>
        </div>
    </div>
</div>
