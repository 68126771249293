import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css']
})
export class SiteLayoutComponent implements OnInit {

    public isHome: boolean = false;

    constructor(private router: Router) {
    }
  
    ngOnInit(): void {
      this.isHome = this.router.url.indexOf('home') > -1;
    }

}
