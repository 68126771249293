import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../services/auth-service.service';


@Injectable()
export class AuthGuardService implements CanActivate {
    // constructor(private router: Router) { }

    // canActivate(): boolean {
    //     console.log("---------------------------" + localStorage.getItem('accessToken') );
    //     if (!localStorage.getItem('accessToken')) {
    //       console.log("Hello guards");
    //         this.router.navigate(['/sign-in']);
    //         return false;
    //     }
    //     return true;
    // }
    constructor(public auth: AuthService, public router: Router) {}
    canActivate(): boolean {
      if (!this.auth.isAuthenticated()) {
        this.router.navigate(['/sign-in']);
        return false;
      }
      return true;
    }
}
