<ul
  class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion sidebarMenu"
  id="accordionSidebar"
>
  <li class="nav-item">
    <a class="nav-link" routerLink="/admin">
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      Dashboard
    </a>
  </li>
  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['HUB'] && menuRole['HUB'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#hubMangement"
      aria-expanded="false"
      aria-controls="hubMangement"
    >
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Hubs</span>
    </a>
    <div
      id="hubMangement"
      class="collapse headingBorder"
      aria-labelledby="headingTwo"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['HUB'] && menuRole['HUB'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/hub/list"
          routerLinkActive="active"
          >Hub List</a
        >
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['HUB'] && menuRole['HUB'].activity.WRITE == true)
          "
          class="collapse-item"
          routerLink="/admin/hub/create"
          routerLinkActive="active"
          >Hub Create</a
        >
      </div>
    </div>
  </li>
  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_VEHICLE'] &&
        menuRole['ADMIN_VEHICLE'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link collapsed"
      data-toggle="collapse"
      data-target="#vehicleManagement"
      aria-expanded="false"
      aria-controls="vehicleManagement"
    >
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Vehicles</span>
    </a>
    <div
      id="vehicleManagement"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_VEHICLE'] &&
              menuRole['ADMIN_VEHICLE'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/vehicles/list"
          routerLinkActive="active"
          >Vehicle List</a
        >
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_VEHICLE'] &&
              menuRole['ADMIN_VEHICLE'].activity.WRITE == true)
          "
          class="collapse-item"
          routerLink="/admin/vehicles/add"
          routerLinkActive="active"
          >Add new vehicle</a
        >
      </div>
    </div>
  </li>

  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_VEHICLE_RENT'] &&
        menuRole['ADMIN_VEHICLE_RENT'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link collapsed"
      data-toggle="collapse"
      data-target="#vehicleRent"
      aria-expanded="false"
      aria-controls="vehicleRent"
    >
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Vehicle Rents</span>
    </a>
    <div
      id="vehicleRent"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_VEHICLE_RENT'] &&
              menuRole['ADMIN_VEHICLE_RENT'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/rents/list"
          routerLinkActive="active"
          >Vehicle Rent List</a
        >
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_VEHICLE_RENT'] &&
              menuRole['ADMIN_VEHICLE_RENT'].activity.WRITE == true)
          "
          class="collapse-item"
          routerLink="/admin/rents/add"
          routerLinkActive="active"
          >Vehicle Rent Create</a
        >
      </div>
    </div>
  </li>
  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_VEHICLE_SERVICE'] &&
        menuRole['ADMIN_VEHICLE_SERVICE'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link"
      routerLink="/admin/vehicle-servicing-dashboard/list"
      aria-expanded="false"
      routerLinkActive="active"
    >
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span> Vehicle Servicing</span>
    </a>
  </li>
  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['REMARK'] && menuRole['REMARK'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link"
      routerLink="/admin/remarks/list"
      aria-expanded="false"
      routerLinkActive="active"
    >
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span> Remarks</span>
    </a>
  </li>
  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_DAMAGE'] &&
        menuRole['ADMIN_DAMAGE'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a class="nav-link" routerLink="/admin/damage/list" aria-expanded="false">
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Damage Reports</span>
    </a>
    <div
      id="damageReports"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_DAMAGE'] &&
              menuRole['ADMIN_DAMAGE'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/damage/reports"
          routerLinkActive="active"
          >List</a
        >
      </div>
    </div>
  </li>
  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_CONTACT'] &&
        menuRole['ADMIN_CONTACT'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a class="nav-link" routerLink="/admin/contacts/list">
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      Contact Us
    </a>
  </li>
  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_USER'] && menuRole['ADMIN_USER'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link collapsed"
      data-toggle="collapse"
      data-target="#userManagement"
      aria-expanded="false"
      aria-controls="userManagement"
    >
      <img
        src="../../../../assets/img/user_Management_icon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Users</span>
    </a>
    <div
      id="userManagement"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_USER'] &&
              menuRole['ADMIN_USER'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/users/list"
          routerLinkActive="active"
          >User List</a
        >
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_USER'] &&
              menuRole['ADMIN_USER'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/users/admin-list"
          routerLinkActive="active"
          >User Privileges</a
        >
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_USER'] &&
              menuRole['ADMIN_USER'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/users/group-list"
          routerLinkActive="active"
          >User Group</a
        >
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_USER'] &&
              menuRole['ADMIN_USER'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/users/activity-log"
          routerLinkActive="active"
          >Activity Log</a
        >
      </div>
    </div>
  </li>
  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_CONTENT'] &&
        menuRole['ADMIN_CONTENT'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#staticContent"
      aria-expanded="false"
      aria-controls="userManagement"
    >
      <img
        src="../../../../assets/img/user_Management_icon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Static Contents</span>
    </a>
    <div
      id="staticContent"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_CONTENT_OFFER'] &&
              menuRole['ADMIN_CONTENT_OFFER'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/static-contacts/offers"
          routerLinkActive="active"
        >
          <img
            src="../../../../assets/img/Hubicon.svg"
            class="rounded"
            alt=""
            width="20"
            height="20"
          />
          Offers
        </a>
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_CONTENT_SERVICE'] &&
              menuRole['ADMIN_CONTENT_SERVICE'].activity.READ == true)
          "
          class="nav-link"
          routerLink="/admin/static-contacts/services"
          routerLinkActive="active"
        >
          <img
            src="../../../../assets/img/Hubicon.svg"
            class="rounded"
            alt=""
            width="20"
            height="20"
          />
          Services
        </a>
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_CONTENT_SERVICE'] &&
              menuRole['ADMIN_CONTENT_SERVICE'].activity.READ == true)
          "
          class="nav-link"
          routerLink="/admin/static-contacts/about-us"
          routerLinkActive="active"
        >
          <img
            src="../../../../assets/img/Hubicon.svg"
            class="rounded"
            alt=""
            width="20"
            height="20"
          />
          About Us
        </a>
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_CONTENT_SERVICE'] &&
              menuRole['ADMIN_CONTENT_SERVICE'].activity.READ == true)
          "
          class="nav-link"
          routerLink="/admin/static-contacts/admin-policy/create"
          routerLinkActive="active"
        >
          <img
            src="../../../../assets/img/Hubicon.svg"
            class="rounded"
            alt=""
            width="20"
            height="20"
          />
          Terms and Conditions
        </a>

        <!-- <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_CONTENT_OFFER'] &&
              menuRole['ADMIN_CONTENT_OFFER'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/static-contacts/testimonials"
          routerLinkActive="active"
        >
          <img
            src="../../../../assets/img/Hubicon.svg"
            class="rounded"
            alt=""
            width="20"
            height="20"
          />
          Terminals
        </a> -->
      </div>
    </div>
  </li>

  <!-- <li *ngIf="(superAdmin.SUPERADMIN === true) || (menuRole['ADMIN_BOOKING'] && menuRole['ADMIN_BOOKING'].activity.READ == true)" class="nav-item " routerLinkActive="active">
        <a class="nav-link" routerLink="/admin/booking">
            <img src="../../../../assets/img/Hubicon.svg" class="rounded" alt="" width="20" height="20"> Bookings
        </a>
    </li> -->

  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_BOOKING'] &&
        menuRole['ADMIN_BOOKING'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link collapsed"
      href="#"
      data-toggle="collapse"
      data-target="#bookingManagement"
      aria-expanded="false"
      aria-controls="bookingManagement"
    >
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Bookings</span>
    </a>
    <div
      id="bookingManagement"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          class="collapse-item"
          routerLink="/admin/booking/list"
          routerLinkActive="active"
          >Booking List</a
        >
        <a
          class="collapse-item"
          routerLink="/admin/booking/created"
          routerLinkActive="active"
          >Booking Created</a
        >
        <a
          class="collapse-item"
          routerLink="/admin/booking/waiting"
          routerLinkActive="active"
          >Booking Pending</a
        >
        <a
          class="collapse-item"
          routerLink="/admin/booking/start"
          routerLinkActive="active"
          >Booking On Trip</a
        >
        <!-- <a class="collapse-item" routerLink="/admin/booking/extension" routerLinkActive="active">Booking Extension</a> -->
        <a
          class="collapse-item"
          routerLink="/admin/booking/endtrip"
          routerLinkActive="active"
          >Booking End Trip</a
        >
        <a
          class="collapse-item"
          routerLink="/admin/booking/cancel"
          routerLinkActive="active"
          >Booking Cancel</a
        >
        <!-- <a class="collapse-item" routerLink="/admin/booking/cancel" routerLinkActive="active">Booking Cancel</a> -->
        <a
          class="collapse-item"
          routerLink="/admin/booking/penalty"
          routerLinkActive="active"
          >Booking Penalty List</a
        >
      </div>
    </div>
  </li>

  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_ACCOUNT'] &&
        menuRole['ADMIN_ACCOUNT'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link collapsed"
      data-toggle="collapse"
      data-target="#accoiuntManagement"
      aria-expanded="false"
      aria-controls="accoiuntManagement"
    >
      <img
        src="../../../../assets/img/user_Management_icon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Accounts</span>
    </a>
    <div
      id="accoiuntManagement"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <!-- <a class="collapse-item" routerLink="/admin/account/list"  routerLinkActive="active">Account List</a> -->
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_ACCOUNT_BILLING'] &&
              menuRole['ADMIN_ACCOUNT_BILLING'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/account/billing"
          routerLinkActive="active"
          >Billing List</a
        >
      </div>
    </div>
  </li>
  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_PROMOCODE'] &&
        menuRole['ADMIN_PROMOCODE'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link collapsed"
      data-toggle="collapse"
      data-target="#promocode"
      aria-expanded="false"
      aria-controls="promocode"
    >
      <img
        src="../../../../assets/img/user_Management_icon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Promo Code</span>
    </a>
    <div
      id="promocode"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_PROMOCODE'] &&
              menuRole['ADMIN_PROMOCODE'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/promocode/list"
          routerLinkActive="active"
        >
          List</a
        >
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_PROMOCODE'] &&
              menuRole['ADMIN_PROMOCODE'].activity.WRITE == true)
          "
          class="collapse-item"
          routerLink="/admin/promocode/add"
          routerLinkActive="active"
        >
          Add</a
        >
      </div>
    </div>
  </li>

  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_DRIVING'] &&
        menuRole['ADMIN_DRIVING'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link"
      routerLink="/admin/driving-training-dashboard/list"
      routerLinkActive="active"
    >
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>Driving Training</span>
    </a>
  </li>

  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_APP_VERSION'] &&
        menuRole['ADMIN_APP_VERSION'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a
      class="nav-link collapsed"
      data-toggle="collapse"
      data-target="#appversion"
      aria-expanded="false"
      aria-controls="appversion"
    >
      <img
        src="../../../../assets/img/user_Management_icon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span>App Version</span>
    </a>
    <div
      id="appversion"
      class="collapse headingBorder"
      aria-labelledby="headingUtilities"
      data-parent="#accordionSidebar"
    >
      <div class="py-2 collapse-inner subMenuList">
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_APP_VERSION'] &&
              menuRole['ADMIN_APP_VERSION'].activity.READ == true)
          "
          class="collapse-item"
          routerLink="/admin/app-version/list"
          routerLinkActive="active"
        >
          List</a
        >
        <a
          *ngIf="
            superAdmin.SUPERADMIN === true ||
            (menuRole['ADMIN_APP_VERSION'] &&
              menuRole['ADMIN_APP_VERSION'].activity.WRITE == true)
          "
          class="collapse-item"
          routerLink="/admin/app-version/add"
          routerLinkActive="active"
        >
          Add</a
        >
      </div>
    </div>
  </li>

  <li
    *ngIf="
      superAdmin.SUPERADMIN === true ||
      (menuRole['ADMIN_REPORT'] &&
        menuRole['ADMIN_REPORT'].activity.READ == true)
    "
    class="nav-item"
    routerLinkActive="active"
  >
    <a class="nav-link" routerLink="/admin/reports" routerLinkActive="active">
      <img
        src="../../../../assets/img/Hubicon.svg"
        class="rounded"
        alt=""
        width="20"
        height="20"
      />
      <span> Report</span>
    </a>
  </li>
</ul>
