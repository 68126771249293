import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth-service.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public isLoggedIn: boolean = false;
  public userCatagory: any;
  emergency_number_list:any =[];

  constructor(
  	public authService: AuthService,
    private router: Router
  	) { }

  ngOnInit(): void {

    this.authService.getEmergencyNumber().subscribe((data)=>{
      this.emergency_number_list = data['payload'];
    });



    // console.log("hellooooooooooooooooo");

  	// this.isLoggedIn = this.authService.isLoggedin();
    // let userInfo = JSON.parse(localStorage.userProfile);
    // this.userCatagory = userInfo["catagory"];
    // console.log(userInfo, userInfo["catagory"]);
    // console.log(this.userCatagory);

    // console.log("Check response", this.userCatagory);
    // console.log(JSON.parse(localStorage.userProfile));
    // console.log(localStorage);
  }

  logOut(){
    // Swal.fire(
    //   'Thank you! Logout success',
    //   'You clicked the button!',
    //   'success'
    // );

    Swal.fire({
      title: 'Are you sure want to Log Out?',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f26522',
      cancelButtonColor: '#3d1c0c',
      confirmButtonText: 'Yes, Log out!'
    }).then((result) => {
      if (result.value) {
        this.authService.removeToken();
        this.router.navigateByUrl('');
        Swal.fire(
          'Log Out Successful!',
          'Your\'re successfully logout',
          'success'
        )
      }
    })

  }

}
