// import { AgmCoreModule } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
// import { AmazingTimePickerModule } from 'amazing-time-picker';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { VehicleModule } from './vehicle/vehicle.module';
import { AuthInterceptor } from './shared/common/interceptor/auth.interceptor';
import { AuthService } from './shared/services/auth-service.service';
// import { UserModule } from './user/user.module';
import { AuthGuardService } from './shared/common/guard/auth-guard.service';
// import { AdminPanelModule } from './admin-panel/admin-panel.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { DashboardModule } from './dashboard/dashboard.module';
import { AllModulesModule } from './modules/all-modules.module';
// import { MouseEvent } from '@agm/core';

// firebase environments configuration

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { MessagingService } from './shared/messaging.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { PipesModule } from './pipes-module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RecaptchaV3Module,
    AppRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    TypeaheadModule.forRoot(),
    ToastrModule.forRoot({
      enableHtml: true,
      tapToDismiss: true,
      newestOnTop: true,
      timeOut: 5000,
      progressBar: true,
      progressAnimation: 'decreasing',
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    PipesModule,
    NgxPaginationModule,
    // AmazingTimePickerModule,
    HttpClientModule,
    SharedModule,

    // AgmCoreModule.forRoot({
    //     apiKey: 'AIzaSyAADB5BE84MpHKK7qdJZO6kKO-36DoTz0U'
    // })

    // firebase configuration
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    AuthService,
    AuthGuardService,
    MessagingService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LcalUwaAAAAAHiuhtdRgSxP2uo3y092t8r1EOhz',
    },
  ],
  exports: [PipesModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
