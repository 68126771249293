<div class="dashboardHeader_top_sec">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-md-3">
                <a class="sidebar-brand d-flex  dashboard_logo" routerLink="">
                    <img src="../../../../assets/img/logo.png" class="img-fluid" alt="" width="180">
                </a>
            </div>
            <div class="col-md-6 text-center">
                <span class="heading_tag_line">Welcome to ChaadRide Hub Dashboard</span>
            </div>
            <div class="col-md-3">
                <ul class="navbar-nav margin-right d-flex align-items-end flex-column">
                    <li class="nav-item dropdown no-arrow align-text-bottom">
                        <a class="nav-link dropdown-toggle text-black-50 profile_text" href="#" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <p class="mr-2 person_name">{{userInfo.fullName}}<br /> {{userInfo.email}}</p>
                            <span class="profile_icon"><img src="{{userInfo.image}}" alt="" width="45" height="45"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                             <a class="dropdown-item" style="cursor: pointer;" (click)="logOut()" data-toggle="modal" data-target="#logoutModal">
                                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
