import { AuthService } from './shared/services/auth-service.service';
import { MessagingService } from "./shared/messaging.service";
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Idle } from 'idlejs/dist';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  toastr: any;
  // firebase massage
  message: any;

  constructor(
      private messagingService: MessagingService,
      public authService: AuthService,
    private router    : Router,
    ){}
  title = 'front';
  idle = new Idle()
    // .whenNotInteractive()
    // .within(30)
    // .do(() => {
    //   this.authService.removeToken();
    //   this.showSuccess("Time out please Sign In again", "Time out error");
    //   this.router.navigate(["/sign-in"]);
    // }).start();

    // Firebase message requestPermission by current user
    ngOnInit() {
        this.notifyMe();
        // console.log('Browser permission');
        // To DO: get current userId then hit requestPermission
    }

    // Notification permission alert
    notifyMe() {
        console.log('============Notification',Notification.permission)
        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
          alert("This browser does not support desktop notification");
        }
      
        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
          // If it's okay let's create a notification
        //   var notification = new Notification("Hi there!");
        // todo: if need to send with userId
        const userId = 'user001';
        this.messagingService.requestPermission(userId)
        this.messagingService.receiveMessage()
        this.message = this.messagingService.currentMessage
        }
      
        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
          Notification.requestPermission().then(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              //var notification = new Notification("Hi there!");
            }
          });
        }
        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
      }



  showSuccess(msg: string, title: string) {
    this.toastr.success(msg, title, {
        enableHtml: true,
        tapToDismiss: true,
        timeOut: 5000,
        progressBar: true,
        progressAnimation: "decreasing",
        positionClass: 'toast-top-right',
    }).onTap
        .pipe(take(1))
        .subscribe(() => this.toasterClickedHandler());
  }
  toasterClickedHandler() {}
}
