<app-admin-dashboard-header></app-admin-dashboard-header> 
<div id="wrapper">
    <aside class="dashboardLeftSidebar">
        <app-admin-dashboard-sidebar></app-admin-dashboard-sidebar>     
    </aside>
    <div class="dashboardPageWrapper">        
        <div class="mainDashboardSec py-3 pl-3 pr-3">
            <div class="containe-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="containe-fluid">
            <app-admin-dashboard-footer></app-admin-dashboard-footer>
        </div>
    </div>
</div>
