<footer>
  <div class="footer_top">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-3">
          <a class="footer_logo" href="#">
            <img
              src="../../../../assets/images/chaad-ride-logo.png"
              class="img-fluid"
              alt=""
              style="width: 150px"
            />
          </a>
          <div class="chaadride_contact">
            <p>
              Flat # 10/C, Level # 9, Mezbahuddin Plaza, 91, New Circular Road,
              Ramna, Dhaka-1217, Bangladesh.<br />Mobile: +8801715012345<br />&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;02-48313265<br />Email:
              <a href="mailto:info@chaadride.com">info@chaadride.com</a>
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-2">
          <h4>OUR INFO</h4>
          <ul class="our_info highlight">
            <li>
              <a routerLink="/">Home</a>
            </li>
            <li>
              <a
                (click)="onClick($event)"
                routerLink="/about-us"
                routerLinkActive="active"
                >About Us</a
              >
            </li>
            <li>
              <a
                routerLink="/hubs/register"
                *ngIf="authService.isLoggedin()"
                routerLinkActive="active"
                (click)="onClick($event)"
              >
                Hub Registration
              </a>
            </li>
            <li>
              <a
                (click)="onClick($event)"
                routerLink="/gallery"
                routerLinkActive="active"
                >Vehicle List</a
              >
            </li>
            <li>
              <a routerLink="/faq" routerLinkActive="active">FAQ</a>
            </li>
            <li>
              <a routerLink="/services" routerLinkActive="active">Services</a>
            </li>
            <li>
              <a
                routerLink="/contact-us"
                routerLinkActive="active"
                (click)="onClick($event)"
                >Contact Us</a
              >
            </li>
            <li>
              <a
                routerLink="/auth/sign-in"
                *ngIf="!authService.isLoggedin()"
                routerLinkActive="active"
                >Sign in</a
              >
            </li>
          </ul>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-2">
          <div class="">
            <h4>USEFUL LINKS</h4>
            <ul class="our_info">
             <!-- <li>
                <a href="/privacy-policy">Privacy Policies</a>
              </li>-->
              <li>
                <a routerLink="/terms-condition" routerLinkActive="active"
                  >Terms & Conditions</a
                >
              </li>
              <li>
                <a
                  routerLink="/auth/sign-up"
                  *ngIf="!authService.isLoggedin()"
                  routerLinkActive="active"
                  >Registration</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <img src="assets/images/yellow-scoty.png" class="img-fluid footer_bike" alt="" />
  </div>
  <div class="footer_bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-8 copywrite">
          <p>
            &copy; ChaadRide.com
            <span
              >Powered by<a href="{{ footerTextLink }}" target="_blank">{{
                footerText
              }}</a></span
            >
          </p>
        </div>
        <div class="d-none d-sm-none d-md-none d-lg-block col-lg-4">
          <div class="footer_social">
            <ul>
              <li>
                <a target="_blank" href="https://www.facebook.com/chaadride"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                <a target="_blank" href="https://www.linkedin.com/company/chaad-ride/"
                ><i class="fab fa-linkedin"></i
                ></a>
              </li>
              <li>
                <a target="_blank" href="https://www.instagram.com/chaad.ride/"><i class="fab fa-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
